<template>
    <div class="contaner">
       <div class="iconImg">
         <img src="../../assets/successIcon2.png"/>
         <!-- <div class="title2">无需重复提交</div> -->
         <div class="text" style="margin-top:30px">您已是股东,无需激活</div>
         <div class="successBtn" @click="submit(0)">点击进入股东中心</div>
       </div>

       <!-- <div class="iconImg"  v-if="dataList.audit.audit_state==0||dataList.audit.audit_state==1">
         <img src="../../assets/audit_error.png"/>
         <div class="title2">正在申请中</div>
         <div class="text2">2-3个工作日内完成请您耐心等候</div>
         <div class="errorBtn" @click="lookDetail">查看审核详情</div>
         <div class="message" @click="lookDetail">查看审核详情 ></div>
       </div> -->
    </div>
</template>
<script scoped type="">
// import TencentCaptcha from 'vue-tencent-captcha';
// import store from '../../store/index.js';
// import captcha from "@/components/captcha";
import * as apiCommon from "@/api/common";
export default {
  components: {
    // captcha
  },
  data() {
    return {
      value: "",
      codeData: "",
      captchaConfig: {
        title: "获取验证码",
        mobile: "",
        type: 6,
        code: "",
        imgToken:""
      },
      tableData:{
        union_audit:{

        },
        user:{}
      },
      dataList:{
        audit:{}
      }
    };
  },
  created() {
    document.title = "身份激活";
    // 判断用户
    // this.applyUser();
    // this.getToken();
    // 判断用户
    this.applyUser();
  },
  methods: {
    async applyUser() {
      let res = await apiCommon.getSetting();
      console.log(res, "res");
      if (res.code == 0) {
        this.dataList = res.data
      }
    },
    submit(){
      // if(index==0){
        this.$router.push("../lador/ladorLogin");
      // }else{
        // this.$router.push(`./modification?id=${this.tableData.union_audit.audit_id}`)
      // }
    },
    lookDetail(){
      if(this.dataList.audit.audit_type==1){
        this.$router.push(`../shareholders/online/onlineDetails?audit_id=${this.dataList.audit.audit_id}`)
      }else{
        this.$router.push(`../shareholders/offline/flow?audit_id=${this.dataList.audit.audit_id}`)
      }
      
    }
  }
};
</script>
<style scoped>
  .iconImg{
    margin: 300px auto 0;
    text-align: center;
  }
  .iconImg img{
    width:180px;
    height: 180px;
    margin: 0 285px 0 
  }
  .title2{
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #101010;
    margin: 22px 0 10px 
  }
  .text{
    font-size: 35px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #07C160;
    margin: 10px 0
  }
  .text1{
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin-top:22px 
  }
  .successBtn{
    background-color: #07C160;
    width: 350px;
    height: 90px;
    line-height:90px;
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin: 300px auto 0; 
  }
  .text2{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color:#FAA030;
    margin-top:22px 
  }
  .errorBtn{
    background-color: #FAA030;
    width: 350px;
    height: 90px;
    line-height:90px;
    text-align: center;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin: 300px auto 0; 
  }
  .message{
    color:#FAA030;
    font-size: 30px;
    margin-top: 24px
  }

  .contaner{
    background-color: #fff;
  }
</style>
